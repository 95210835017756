import * as React from "react";
import { graphql, Link, Script } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import moment from "moment";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import KonzertKarte from "../components/KonzertKarte";

const KonzertarchivPage = ({ data }) => {
  return (
    <Layout>
      <div className="container mx-auto mt-40 mb-10">
        <h1 className="font-header text-3xl text-center md:text-6xl">
          Konzertarchiv
        </h1>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-8 mt-10">
          {data.allWpTermin.edges
            .sort(
              (a, b) =>
                moment(
                  b.node.termine.zeitpunktBeginn,
                  "DD.MM.YYYY HH:mm"
                ).unix() -
                moment(
                  a.node.termine.zeitpunktBeginn,
                  "DD.MM.YYYY HH:mm"
                ).unix()
            )
            .map((termin) => {
              if (
                moment(
                  termin.node.termine.zeitpunktEnde,
                  "DD.MM.YYYY HH:mm"
                ).isBefore()
              ) {
                return (
                  <KonzertKarte key={termin.node.id} termin={termin.node} />
                );
              }
            })}
        </div>
      </div>
    </Layout>
  );
};

export const KonzertarchivQuery = graphql`
  query KonzertQuery {
    allWpTermin {
      edges {
        node {
          title
          uri
          id
          termine {
            weitereInfo
            zeitpunktBeginn
            zeitpunktEnde
            ort
          }
        }
      }
    }
  }
`;

export default KonzertarchivPage;
